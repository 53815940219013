//import logo from "./logo.svg";
import "./App.css";
import { Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  CarOutlined,
  UsergroupAddOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Divider, Layout, Menu, Row } from "antd";
import React, { useState } from "react";
// Import the functions you need from the SDKs you need
// Roadside Assist WebApp

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useEffect } from "react";
import { Typography } from "antd";
import SubHeader from "./components/subheader";
import { useDispatch, useSelector } from "react-redux";

import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { resetState, updateAuthStatus } from "./store/reducers/authReducer";
import { buildTimeValue } from "@testing-library/user-event/dist/utils";
// import logo from "../src/assets/images/Elog Book LOGO-02.jpeg";
import logo from "../src/assets/images/white-.png";
import { Toaster } from "react-hot-toast";
import { appVersion } from "./store/utils/APIUtils";
const { Header, Content, Footer, Sider } = Layout;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzrjlxiP450JFJ0Al0rkl3e0XYWSDHWvA",
  authDomain: "picobyte-solutions.firebaseapp.com",
  projectId: "picobyte-solutions",
  storageBucket: "picobyte-solutions.appspot.com",
  messagingSenderId: "992029135546",
  appId: "1:992029135546:web:5fc9263da0474e67a46883",
  measurementId: "G-CX7QCT7G4P",
};

const { Title } = Typography;

function App() {
  const MenuList = [
    "Dashboard",
    "Vehicles",
    "Drivers",
    //"Settings",
    "Users",
    "Support",
  ];
  const navigate = useNavigate();
  let state = useSelector((state) => state);
  const [collapsed, setCollapsed] = useState(false);

  const dispatch = useDispatch();
  const currentPath = useLocation();
  // const [is_authenticated, setAuthenticated] = useState(false);
  const access_token = useSelector((state) => state?.persist?.auth?.token);
  const is_authenticated = useSelector(
    (state) => state?.persist?.auth?.is_authenticated
  );

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    // Initialize Firebase Authentication and get a reference to the service
    const auth = getAuth(app);
    // if (app.auth)
    console.log("auth", auth);

    console.info("route", currentPath);

    onAuthStateChanged(auth, (user) => {
      console.log("onAuthStateChanged user", user);
      if (user && access_token !== "" && access_token !== undefined) {
        dispatch(updateAuthStatus(true));
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        navigate(currentPath);
        if (currentPath.pathname == "/" && user) {
          navigate("/app/dashboard");
        }
      }
      if (!user && access_token !== "" && access_token !== undefined) {
        // User is signed out
        // ...
        dispatch(updateAuthStatus(false));
        navigate("/login");
      }
      if (currentPath.pathname == "/" && !user) {
        // User is signed out
        navigate("/login");
      }
    });
  }, []);

  const navigateToMenu = (evt) => {
    console.log("navigateToMenu", evt.key);

    if (evt.key.includes("Dashboard")) {
      navigate("/app/dashboard");
    }

    if (evt.key.includes("Vehicles")) {
      navigate("/app/vehicles");
    }

    if (evt.key.includes("Drivers")) {
      navigate("/app/drivers");
    }

    // if (evt.key.includes("Settings")) {
    //   navigate("/app/settings");
    // }
    if (evt.key.includes("Users")) {
      navigate("/app/Users");
    }
  };

  const logoutHandler = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("before Reset State", state);
        dispatch(resetState());
        dispatch(updateAuthStatus(false));
        loginHandler();
        window.location.reload();
      })
      .catch((error) => {
        // An error happened.
      });
    localStorage.removeItem("isDesktop");
  };

  const loginHandler = () => {
    navigate("/login");
  };

  const createAccountHandler = () => {
    navigate("/signup");
  };

  const operatorProfile = useSelector(
    (state) =>
      state?.persist?.operator?.operatorProfile?.operator?.operatorOrganization
        ?.name
  );

  return (
    <div className="App">
      {/* this is always shown in header */}
      <header className="App-header">
        <div className="App-navbar">
          <div className="App-navitem">
            <Row
              style={{
                paddingLeft: 10,
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                // width={90}
                // height={50}
                height={60}
                width={60}
                style={{ borderRadius: 8 }}
              />
              <Title
                type="secondary"
                level={4}
                style={{
                  color: "white",
                  paddingLeft: 20,
                  paddingTop: 10,
                  display: is_authenticated ? "flex" : "none",
                }}
              >
                {operatorProfile}
              </Title>
            </Row>
          </div>
          <div className="App-navitem">
            <div
              className="App-navitem"
              style={{ display: is_authenticated ? "flex" : "none" }}
            >
              <Button onClick={logoutHandler} type={"danger"}>
                S I G N O U T
              </Button>
            </div>
            {window.location.pathname !== "/login" ? (
              <div
                className="App-navitem"
                style={{
                  display:
                    window.location.pathname === "/signup" ? "flex" : "none",
                }}
              >
                <Button
                  onClick={loginHandler}
                  style={{
                    borderRadius: 5,
                    // background: "#b1fcf5",
                    color: "black",
                  }}
                >
                  L O G I N
                </Button>
              </div>
            ) : (
              <div
                className="App-navitem"
                style={{
                  display:
                    // !is_authenticated &&
                    // (access_token === "" || access_token === undefined)
                    //   ? "flex"
                    //   : "none",
                    window.location.pathname == "/login" ? "flex" : "none",
                }}
              >
                <Button
                  onClick={createAccountHandler}
                  style={{
                    borderRadius: 5,
                    // background: "#b1fcf5",
                    color: "black",
                  }}
                >
                  S I G N U P
                </Button>
              </div>
            )}
          </div>
        </div>
      </header>

      <Layout style={{ height: "100vh" }}>
        <Toaster position="top-center" reverseOrder={false} />
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
            setCollapsed(collapsed);
          }}
          style={{
            display: is_authenticated ? "flex" : "none",
            position: "relative",
          }}
        >
          <div className="logo" style={{ marginTop: 30 }} />
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["4"]}
            items={[
              AppstoreOutlined,
              CarOutlined,
              UserOutlined,
              UsergroupAddOutlined,
            ].map((icon, index) => ({
              key: String(MenuList[index]),
              icon: React.createElement(icon),
              label: `${MenuList[index]}`,
              onClick: (evt) => {
                navigateToMenu(evt);
              },
            }))}
          />
          {/* App version */}
          {!collapsed && (
            <div
              style={{
                position: "absolute",
                bottom: 80,
                width: "100%",
                fontWeight: "bold",
                textAlign: "center",
                color: "#8C9A9B",
                fontSize: "12px",
              }}
            >
              App Version: {appVersion}
            </div>
          )}
        </Sider>

        <Layout style={{ minHeight: "100vh" }}>
          <Content
            style={{
              minHeight: 360,
              paddingBottom: "80px", // Ensure there's space for the footer
            }}
            className="site-layout-background"
          >
            <Outlet />
          </Content>
          {/* <Footer
            style={{
              textAlign: "center",
              zIndex: 1,
              position: "fixed",
              bottom: 0,
              width: "100%",
              backgroundColor: "#F5F5F5",
              padding: "10px",
              color: "black",
            }}
          >
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <a href="https://userguide.elogbook.co.nz/" target="_blank">
                Help
              </a>
              <Divider type="vertical" />
              <a href="https://elogbook.co.nz/contact/" target="_blank">
                Support
              </a>
              <Divider type="vertical" />
              <a href="https://elogbook.co.nz/privacy-policy/" target="_blank">
                Privacy Policy
              </a>
              <Divider type="vertical" />
              <a href="https://elogbook.co.nz/terms-of-use/" target="_blank">
                Terms of Use
              </a>
            </Row>
            <p>Elogbook @2023 by Picobyte Solutions Limited</p>
          </Footer> */}
        </Layout>
      </Layout>
    </div>
  );
}

export default App;

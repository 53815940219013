import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Spin,
  message,
  Row,
  Col,
  Popconfirm,
  Typography,
  Select,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOperatorMembers,
  addOperatorMember,
  editOperatorMember,
  deleteOperatorMember,
} from "../store/reducers/operatorReducer";
import PhoneInput from "react-phone-number-input";

function OperatorMembers() {
  const [modalState, setModalState] = useState({
    type: null, // 'add', 'edit', 'delete'
    visible: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [filterInput, setFilterInput] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const operatorMembers = useSelector(
    (state) => state?.persist?.operator?.operatorMembers?.data || []
  );
  const { operator: profile } = useSelector(
    (state) => state?.persist?.operator?.operatorProfile
  );

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = () => {
    dispatch(fetchOperatorMembers(access_token)).then((res) => {
      if (res.payload?.data?.error) {
        showMessage("warning", res.payload.data.error);
      }
    });
  };

  const showMessage = (type, content) => {
    messageApi.open({
      type,
      content,
      className: "custom-class",
      style: { marginTop: "10vh" },
    });
  };

  const openModal = (type, member = null) => {
    setModalState({ type, visible: true });
    setSelectedMember(member);
    if (type !== "delete") {
      form.setFieldsValue(member || {});
    }
  };

  const closeModal = () => {
    setModalState({ type: null, visible: false });
    setSelectedMember(null);
    form.resetFields();
  };

  const handleFilter = () => {
    if (!filterInput) return operatorMembers;
    return operatorMembers.filter(({ firstname }) =>
      firstname.toLowerCase().includes(filterInput.toLowerCase())
    );
  };

  const handleAddEdit = (values) => {
    setLoading(true);
    const action =
      modalState.type === "add"
        ? addOperatorMember({ ...values, access_token })
        : editOperatorMember({
            ...values,
            operatorMemberId: selectedMember.id,
            access_token,
          });

    dispatch(action).then((res) => {
      setLoading(false);
      if (res.payload?.data?.success) {
        showMessage("success", res.payload.data.message);
        fetchMembers();
        closeModal();
      } else {
        showMessage("warning", res.payload.data.error);
      }
    });
  };

  const handleDelete = (values) => {
    setLoading(true);
    const payload = {
      operatorMemberIdForDelete: selectedMember.id,
      access_token,
      reason: values.reason,
    };
    dispatch(deleteOperatorMember(payload)).then((res) => {
      setLoading(false);
      if (res.payload?.data?.success) {
        showMessage("success", res.payload.data.message);
        fetchMembers();
        closeModal();
      } else {
        showMessage("warning", res.payload.data.error);
      }
    });
  };

  const columns = [
    { title: "First Name", dataIndex: "firstname", key: "firstname" },
    { title: "Last Name", dataIndex: "lastname", key: "lastname" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone Number", dataIndex: "phoneNumber", key: "phoneNumber" },
    {
      title: "Role",
      dataIndex: "isAdmin",
      key: "isAdmin",
      render: (isAdmin) => <>{isAdmin ? "Admin" : "Staff"}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          {(profile?.isAdmin || profile?.email == record?.email) && (
            <a
              onClick={() => {
                openModal("edit", record);
              }}
              style={{ marginRight: 10 }}
            >
              <EditOutlined /> Edit
            </a>
          )}

          {profile?.email !== record?.email && profile?.isAdmin && (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => {
                openModal("delete", record);
              }}
            >
              <a>
                <DeleteOutlined /> Delete
              </a>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <Spin spinning={loading} size="large">
        <Row justify="space-between" style={{ marginBottom: 10 }}>
          <Col span={12}>
            <Input.Search
              placeholder="Search User by name"
              allowClear
              enterButton="Search"
              onSearch={setFilterInput}
              style={{ width: "50%" }}
            />
          </Col>
          {profile?.isAdmin && (
            <Col span={12} style={{ textAlign: "right" }}>
              <Button type="primary" onClick={() => openModal("add")}>
                ADD USER
              </Button>
            </Col>
          )}
        </Row>

        <Table
          scroll={{ y: 400 }}
          pagination={false}
          columns={columns}
          dataSource={handleFilter()}
          rowKey={(record) => record.id}
        />

        <Modal
          title={modalState.type === "add" ? "Add User" : "Edit User"}
          open={modalState.visible && modalState.type !== "delete"}
          onCancel={closeModal}
          footer={null}
        >
          <Spin spinning={loading} size="large">
            <Form
              layout="vertical"
              form={form}
              onFinish={(values) => {
                handleAddEdit(values);
              }}
            >
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[{ required: true, message: "First name is required" }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[{ required: true, message: "Last name is required" }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  { required: true, message: "Phone number is required" },
                ]}
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="NZ"
                  className="ant-input"
                />
              </Form.Item>
              <Form.Item
                label="Role"
                name="isAdmin"
                rules={[{ required: true, message: "Role is required" }]}
              >
                <Select
                  disabled={!profile?.isAdmin}
                  placeholder="Select role"
                  options={[
                    { value: true, label: "Admin" },
                    { value: false, label: "Staff" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Valid email is required",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  disabled={modalState.type === "edit"}
                />
              </Form.Item>
              <Row justify="end">
                <Button
                  onClick={() => form.resetFields()}
                  style={{ marginRight: 10 }}
                >
                  Reset
                </Button>
                <Button type="primary" htmlType="submit">
                  {modalState.type === "add" ? "Add" : "Update"}
                </Button>
              </Row>
            </Form>
          </Spin>
        </Modal>

        <Modal
          title="Confirm Delete"
          open={modalState.visible && modalState.type === "delete"}
          onCancel={closeModal}
          onOk={() => form.submit()}
          okText="Delete"
        >
          <Spin spinning={loading} size="large">
            <Typography.Text>
              Are you sure you want to delete this user?
            </Typography.Text>
            <Form
              layout="vertical"
              form={form}
              onFinish={(value) => handleDelete(value)}
            >
              <Form.Item
                label="Reason for deletion"
                name="reason"
                rules={[{ required: true, message: "Reason is required" }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </Spin>
    </div>
  );
}

export default OperatorMembers;

import {
  Avatar,
  Card,
  Button,
  Spin,
  Space,
  Row,
  Modal,
  Col,
  Typography,
  message,
  Form,
  Input,
} from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAllVehiclesAPI } from "../store/reducers/vehicleReducer";
import { fetchAllDrivers } from "../store/reducers/driverReducer";
import { updateOptr } from "../store/reducers/operatorReducer";
import { opratorProfile } from "../store/reducers/operatorReducer";
import PhoneInput from "react-phone-number-input";

const { Meta } = Card;
export const Smallcard = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
      //   alignItems: "center",
    }}
  >
    <Card
      style={{
        width: "100%",
        marginTop: 20,
      }}
      size="small"
      type="inner"
      title="Vehicles Registered"
    >
      4 Vehicles
    </Card>
    <Card
      style={{
        width: "100%",
        marginTop: 20,
      }}
      size="small"
      type="inner"
      title="Drivers Registered"
    >
      6 Vehicles
    </Card>
  </div>
);

const Operatordetails = ({ parentToChild }) => {
  const [value, setValue] = useState();
  const [loadingSpin, setLoadingSpin] = useState(false);
  // const opratorProfile = useSelector(state => state?.operator?.operatorProfile?.operator);
  const driver = useSelector((state) => state?.persist?.driver?.drivers);
  const state = useSelector((state) => state?.persist);
  const activeDrivers = [];
  //  const inactiveDriver = useSelector(state => state?.persist?.driver?.inactiveDriver?.data);
  const token = useSelector((state) => state?.persist?.auth?.token);
  const [editoperatormodal, setEditOperatorModal] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { Title } = Typography;
  const operator_current_profile = useSelector(
    (state) => state?.persist?.operator?.operatorProfile,
  );
  const activeDriversMapsLocations = useSelector(
    (state) => state?.persist?.operator?.activeDriversLocations?.data,
  );
  const [form] = Form.useForm();

  console.log("driver123", driver);
  if (driver !== undefined && driver !== null) {
    driver?.map((driverItem) => {
      if (driverItem?.deactiveReason === "") {
        activeDrivers.push(driverItem);
      }
    });
  }
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 10,
    },
  };
  const styles = {
    text_input: {
      textAlign: "left",
    },
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ListofVehicle = useSelector(
    (state) => state?.persist?.vehicle?.vehiclesList,
  );

  const editProfile = () => {
    setEditOperatorModal(true);
    if (
      operator_current_profile !== undefined &&
      operator_current_profile !== null
    ) {
      form.setFieldsValue({
        firstname: operator_current_profile.operator.first_name,
        lastname: operator_current_profile.operator.last_name,
        phoneNumber: operator_current_profile.operator.phoneNumber,
        email: operator_current_profile.operator.email,
        phoneNumber: operator_current_profile.operator.phoneNumber,
        // operator_organization_name: operator_current_profile.operator.operatorOrganization.name
      });
    }
    // navigate("/app/editoperatorprofile");
  };

  const closeEditOperatorModal = () => {
    setEditOperatorModal(false);
  };

  useEffect(() => {
    // dispatch(fetchAllVehiclesAPI(token));
    // dispatch(fetchAllDrivers(token)).then(() => {
    //   // alert(JSON.stringify(res.payload.data))
    // });
  }, []);

  const onFinish = (operator_updated_profile) => {
    setLoadingSpinner(true);
    const payload = { operator_updated_profile, token };
    dispatch(updateOptr(payload)).then((res) => {
      if (res.payload.data.success) {
        messageApi.open({
          type: "success",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        setEditOperatorModal(false);
        setLoadingSpinner(false);
        dispatch(opratorProfile(token));
      }
    });
  };

  return (
    <div>
      {
        <Spin spinning={loadingSpin} size="large">
          <Card bodyStyle={{ paddingTop: "10px", textAlign: "center" }}>
            <UserOutlined
              style={{
                fontSize: 45,
                paddingTop: "10px",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            />
            {parentToChild !== undefined ? (
              <div style={{ lineHeight: 1.3 }}>
                <p
                  style={{
                    marginBottom: "0px",
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                >
                  {parentToChild.first_name.charAt(0).toUpperCase() +
                    parentToChild.first_name.slice(1).toLowerCase() +
                    " " +
                    parentToChild.last_name?.charAt(0).toUpperCase() +
                    parentToChild.last_name.slice(1).toLowerCase()}
                </p>
                <p style={{ marginBottom: "2px", fontSize: 12, marginTop: 0 }}>
                  {`(${
                    parentToChild?.operatorOrganization?.type?.name || "N/A"
                  })`}
                </p>
                <p style={{ marginBottom: "2px" }}>
                  <PhoneOutlined /> {parentToChild.phoneNumber}
                </p>
                <p
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  <MailOutlined /> {parentToChild.email}
                </p>
              </div>
            ) : (
              <p>Unable to load data</p>
            )}
            <Row style={{ justifyContent: "space-around" }}>
              <Button
                type="outlined"
                icon={<EditOutlined />}
                onClick={editProfile}
              >
                EDIT
              </Button>
            </Row>
          </Card>
        </Spin>
      }
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          //   alignItems: "center",
        }}
      >
        <Card
          style={{
            width: "100%",
            marginTop: 20,
          }}
          size="small"
          type="inner"
          title="Vehicles"
        >
          {ListofVehicle === undefined || ListofVehicle === null ? (
            <p>0</p>
          ) : (
            <p>{ListofVehicle.length}</p>
          )}
        </Card>
        <Card
          style={{
            width: "100%",
            marginTop: 20,
          }}
          size="small"
          type="inner"
          title="Active Drivers"
        >
          {activeDrivers !== undefined ? (
            <p>{activeDrivers.length}</p>
          ) : (
            <p>No Data</p>
          )}
        </Card>
        {/* <Card
          style={{
            width: "100%",
            marginTop: 20,
          }}
          size="small"
          type="inner"
          title="Drivers on Shift"
        >
          {activeDriversMapsLocations !== undefined ? (
            <p>{activeDriversMapsLocations?.length}</p>
          ) : (
            <p>No Data</p>
          )}
        </Card> */}
      </div>
      <Modal
        title="Operator Profile"
        open={editoperatormodal}
        onCancel={closeEditOperatorModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        footer={false}
      >
        <Spin spinning={loadingSpinner} size="large">
          <Col>
            {/* <Title
              direction="horizontal"
              style={{ display: "flex", justifyContent: "center" }}
              level={4}
            >
              
            </Title>
            <h3 style={{ marginTop: "20px" }}>Operator Profile</h3> */}
            {contextHolder}
            <Form
              name="basic"
              {...layout}
              validateMessages={validateMessages}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={styles.text_input}
              form={form}
            // onValuesChange={onFormLayoutChange}
            // disabled={componentDisabled}
            >
              <Form.Item
                name="firstname"
                label="First Name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    required: false,
                    pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                    message: "Enter a valid First Name",
                  },
                ]}
              >
                <Input placeholder="Enter First Name" />
              </Form.Item>
              <Col span={2}></Col>
              <Form.Item
                name="lastname"
                label="Last Name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    required: false,
                    pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                    message: "Enter a valid Last Name",
                  },
                ]}
              >
                <Input placeholder="Enter Last Name" />
              </Form.Item>
              <p style={{ lineHeight: 0, fontSize: 11, marginLeft: "34%" }}>
                Can't edit email
              </p>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "email",
                    message: "Enter the valid email",
                  },
                ]}
              >
                {/* <p style={{ fontSize: 12 }}>Can't edit email</p> */}
                <Input disabled={true} placeholder="Enter Email" />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label="Phone No."
                rules={[
                  {
                    required: true,
                    message: "Please enter zip code",
                  },
                  () => ({
                    validator(_, value) {
                      if (isNaN(value)) {
                        return Promise.reject("Enter valid phone number");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                {/* <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="NZ"
                    style={styles.phoneinput}
                    value={value}
                    onChange={setValue}
                    className="ant-input"
                  /> */}
                <Input />
              </Form.Item>
              {/* <Form.Item
          name="operator_organization_name"
          label="Operator Organization Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={true} placeholder="Operator Organization Name" />
        </Form.Item> */}
              <Form.Item
                wrapperCol={{
                  ...layout.wrapperCol,
                  lg: {
                    offset: 8,
                  },
                  md: {
                    offset: 8,
                  },
                  sm: {
                    offset: 0,
                  },
                }}
              >
                <Button type="primary" htmlType="submit" block>
                  UPDATE
                </Button>
              </Form.Item>
            </Form>
            {/* <br /> */}
          </Col>
        </Spin>
      </Modal>
    </div>
  );
};

export default Operatordetails;


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  updateOperatorAPIsHelper,
  opratorProfileAPIsHelper,
  fetchOperatorMembersAPIsHelper,
  addOperatorMembersAPIsHelper,
  editOperatorMembersAPIsHelper,
  deleteOperatorMembersAPIsHelper,
  fetchRemoveOperatorAPIsHelper,
  fetchActiveDriversLocationsAPIsHelper,
  fetchAddedTeamUserDetailsAPIsHelper,
} from "../../helpers/operatorAPI";
const initialState = {
  operatorProfile: {},
  success_message: "",
  error_message: "",
  operatorMembers: [],
  operatorRemove: [],
  activeDriversLocations: [],
  subscriptionDetails: "",
};

export const opratorProfile = createAsyncThunk(
  "operator/oprator-profile",
  async (access_token, thunkAPI) => {
    try {
      const response = await opratorProfileAPIsHelper(access_token);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateOptr = createAsyncThunk(
  "operator/updateOptr",
  async (payload, thunkAPI) => {
    try {
      const response = await updateOperatorAPIsHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchOperatorMembers = createAsyncThunk(
  "operator/fetchOperatorMemebers",
  async (token, thunkAPI) => {
    try {
      const response = await fetchOperatorMembersAPIsHelper(token);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const addOperatorMember = createAsyncThunk(
  "operator/addOperatorMemeber",
  async (payload, thunkAPI) => {
    try {
      const response = await addOperatorMembersAPIsHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const editOperatorMember = createAsyncThunk(
  "operator/editOperatorMemeber",
  async (payload, thunkAPI) => {
    try {
      const response = await editOperatorMembersAPIsHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);
export const deleteOperatorMember = createAsyncThunk(
  "operator/deleteOperatorMemeber",
  async (payload, thunkAPI) => {
    try {
      const response = await deleteOperatorMembersAPIsHelper(payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchRemoveOperator = createAsyncThunk(
  "operator/fetchRemoveOperator",
  async (token, thunkAPI) => {
    try {
      const response = await fetchRemoveOperatorAPIsHelper(token);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchActiveDriversLocations = createAsyncThunk(
  "operator/fetchActiveDriversLocations",
  async (token, thunkAPI) => {
    try {
      const response = await fetchActiveDriversLocationsAPIsHelper(token);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const fetchAddedTeamUserDetails = createAsyncThunk(
  "operator/fetchAddedTeamUserDetails",
  async (uniqueId, thunkAPI) => {
    try {
      const response = await fetchAddedTeamUserDetailsAPIsHelper(uniqueId);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

export const operatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    updateOperator: (state, action) => {
      state.updateOperatorProfile = action.payload;
    },
    addOperatorMember: (state, action) => {
      state.operatorMembers = action.payload.data;
    },
    setoperatorProfileData: (state, action) => {
      state.operatorStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateOptr.fulfilled, (state, action) => {
        state.success_message = action.payload.data;
      })
      .addCase(updateOptr.rejected, (state, action) => {
        state.operatorProfile = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(opratorProfile.fulfilled, (state, action) => {
        state.operatorProfile = action.payload;
      })
      .addCase(opratorProfile.rejected, (state, action) => {
        state.operatorProfile = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(editOperatorMember.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(editOperatorMember.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchOperatorMembers.fulfilled, (state, action) => {
        state.operatorMembers = action.payload.data;
      })
      .addCase(fetchOperatorMembers.rejected, (state, action) => {
        state.operatorMembers = null;
        console.log("action fetched operator member", JSON.stringify(action));
      })
      .addCase(addOperatorMember.fulfilled, (state, action) => {
        // alert(action.payload.data.message)
        // state.operatorMembers = action.payload.data
        console.log("action", JSON.stringify(action));
      })
      .addCase(addOperatorMember.rejected, (state, action) => {
        // state.operatorMembers = null
        console.log("action added operator member", JSON.stringify(action));
      })
      .addCase(deleteOperatorMember.fulfilled, (state, action) => {
        // alert(action.payload.data.message)
        state.operatorMembers = action.payload.data;
      })
      .addCase(deleteOperatorMember.rejected, (state, action) => {
        // state.operatorMembers = null
        console.log("action deleted operator member", JSON.stringify(action));
      })
      .addCase(fetchRemoveOperator.fulfilled, (state, action) => {
        state.operatorRemove = action.payload.data;
      })
      .addCase(fetchRemoveOperator.rejected, (state, action) => {
        state.operatorRemove = null;
        console.log(
          "action fetched remove operator member",
          JSON.stringify(action),
        );
      })
      .addCase(fetchActiveDriversLocations.fulfilled, (state, action) => {
        state.activeDriversLocations = action.payload.data;
        state.subscriptionDetails = action.payload.data.subscription;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchActiveDriversLocations.rejected, (state, action) => {
        state.activeDriversLocations = null;
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchAddedTeamUserDetails.fulfilled, (state, action) => {
        console.log("action", JSON.stringify(action));
      })
      .addCase(fetchAddedTeamUserDetails.rejected, (state, action) => {
        console.log("action", JSON.stringify(action));
      });
  },
});

export const { setoperatorProfileData } = operatorSlice.actions;

// Action creators are generated for each case reducer function
// export const { fetchDriver } = driverSlice.actions   operatorMembers // addOperatorMembers

export default operatorSlice.reducer;


import { Card, List, Space } from "antd";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { PlayCircleOutlined, StopOutlined } from "@ant-design/icons";
import moment from "moment";

const { Meta } = Card;

function Driverlogslist() {
  const navigate = useNavigate();

  let fetchDriverLogs = useSelector(
    (state) => state?.persist?.driver?.singleLogsofDriver?.data
  );
  const newLogs = [...fetchDriverLogs];
  const renderItems = newLogs?.sort(
    (a, b) => new Date(b?.start) - new Date(a?.start)
  );
  return (
    <div>
      <List
        dataSource={renderItems}
        style={{
          overflowY: "scroll",
          maxHeight: "535px",
          position: "relative",
        }}
        renderItem={(item) => {
          return (
            <>
              {item?.type === "work" ? (
                <>
                  <Card
                    style={{
                      margin: 5,
                      borderLeftWidth: "10px",
                      borderLeftColor: "#0ACF83",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          // width: 500,
                        }}
                      >
                        <p style={{ fontWeight: 600 }}>{item?.vehicleMake}</p>
                        {item?.vehiclePlateNumber ? (
                          <p>
                            Plate No. - #
                            <span style={{ fontWeight: "bold" }}>
                              {item?.vehiclePlateNumber.toUpperCase()}
                            </span>
                          </p>
                        ) : (
                          <span style={{ fontWeight: "bold" }}>
                            Non-driving
                          </span>
                        )}
                      </div>
                      <div>
                        <p>
                          <Space>
                            <span style={{ color: "#0ACF83" }}>
                              {" "}
                              <PlayCircleOutlined /> Started Working{" "}
                            </span>
                            {item?.start === null ? (
                              <span> Ongoing</span>
                            ) : (
                              <span>
                                {" "}
                                {moment(item?.start).format(
                                  "DD-MM-YYYY hh:mm A"
                                )}
                              </span>
                            )}
                          </Space>
                        </p>
                        <p>{item?.start_coord?.formatted_address}</p>
                        <br></br>
                        <p>
                          <Space>
                            <span style={{ color: "#FF0000" }}>
                              {" "}
                              <StopOutlined /> Stopped Working{" "}
                            </span>
                            {item?.stop === null ? (
                              <span> Ongoing</span>
                            ) : (
                              <span>
                                {" "}
                                {moment(item?.stop).format(
                                  "DD-MM-YYYY hh:mm A"
                                )}
                              </span>
                            )}
                          </Space>
                        </p>
                        <p>{item?.stop_coord?.formatted_address}</p>
                      </div>
                    </div>
                  </Card>
                </>
              ) : (
                <>
                  <Card
                    style={{
                      margin: 5,
                      borderLeftWidth: "10px",
                      borderLeftColor: "#4facf7",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          // width: 500,
                        }}
                      >
                        <p style={{ fontWeight: 600 }}>{item?.vehicleMake}</p>
                        {item?.vehiclePlateNumber ? (
                          <p>
                            Plate No. - #
                            <span style={{ fontWeight: "bold" }}>
                              {item?.vehiclePlateNumber.toUpperCase()}
                            </span>
                          </p>
                        ) : (
                          <span style={{ fontWeight: "bold" }}>
                            Non-driving
                          </span>
                        )}
                      </div>
                      <div>
                        <p>
                          <Space>
                            <span style={{ color: "#0ACF83" }}>
                              {" "}
                              <PlayCircleOutlined /> Started Resting{" "}
                            </span>
                            {item?.start === null ? (
                              <span> Ongoing</span>
                            ) : (
                              <span>
                                {" "}
                                {moment(item?.start).format(
                                  "DD-MM-YYYY hh:mm A"
                                )}
                              </span>
                            )}
                          </Space>
                        </p>
                        <p>{item?.start_coord?.formatted_address}</p>
                        <br></br>
                        <p>
                          <Space>
                            <span style={{ color: "#FF0000" }}>
                              {" "}
                              <StopOutlined /> Stopped Resting{" "}
                            </span>
                            {item?.stop === null ? (
                              <span> Ongoing</span>
                            ) : (
                              <span>
                                {" "}
                                {moment(item?.stop).format(
                                  "DD-MM-YYYY hh:mm A"
                                )}
                              </span>
                            )}
                          </Space>
                        </p>
                        <p>{item?.stop_coord?.formatted_address}</p>
                      </div>
                    </div>
                  </Card>
                </>
              )}
            </>
          );
        }}
      />
    </div>
  );
}

export default Driverlogslist;

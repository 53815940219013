import { useNavigate } from "react-router-dom";
import {
  Layout,
  Modal,
  Button,
  Col,
  Input,
  Dropdown,
  Space,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import SubHeader from "../components/subheader";
import { useDispatch, useSelector } from "react-redux";
import { selectVehicle } from "../store/reducers/vehicleReducer";
import VehicleScrollList from "../components/VehiclesScrollList";
import MotoCheck from "../pages/motocheck";
import AddVehicleWithNZTA from "../pages/AddVehicleWithNZTA";
import { pdfLogofDriver } from "../store/reducers/driverReducer";
const { Search } = Input;
const { Header } = Layout;

function VehiclesScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("list-of-vehicles");
  const [open, setOpen] = useState(false);
  const [MotoCheckopen, setMotoCheckOpen] = useState(false);
  const [AddVehicleopen, setAddVehicleopen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const [selectedType, setSelectedType] = useState();
  const [selectedVehicleType, setSelectedVehicleType] = useState("all");
  const vehicles = useSelector(
    (state) => state?.persist?.vehicle?.vehiclesList
  );
  const profile = useSelector(
    (state) => state?.persist?.operator?.operatorProfile?.operator
  );

  const navigateToVehicleDetail = (evt) => {
    console.log("navigateToVehicleDetail", evt?.id);
    dispatch(selectVehicle(evt));
    navigate(`/app/vehicles/vehicledetails/${evt?.id}`);
  };

  const navigateToListOfVehicles = (evt) => {
    setOpen(false);
    setAddVehicleopen(false);
    console.log("navigateTo list-of-vehicles");
  };
  const filterData = () => {
    if (filterInput === "") return selectedType ? selectedType : vehicles;
    if (isNaN(filterInput)) {
      return vehicles.filter(({ plateNumber }) =>
        plateNumber.toUpperCase().includes(filterInput.toUpperCase())
      );
    }
    if (isNaN(selectedType)) {
      return selectedType.filter(({ plateNumber }) =>
        plateNumber.toUpperCase().includes(filterInput.toUpperCase())
      );
    }
  };

  const dropDownHandleChange = (value) => {
    setSelectedVehicleType(value);
    if (value == "all") {
      setSelectedType(vehicles);
      return;
    }
    setSelectedType(vehicles?.filter((res) => res?.type === value));
  };

  useEffect(() => {
    filterData();
  }, [filterInput.length == 0]);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
    setMotoCheckOpen(false);
    setAddVehicleopen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const selectActiveComponent = () => {
    if (selectedMenu == "list-of-vehicles") {
      return (
        <VehicleScrollList
          onSelectVehicleHandler={navigateToVehicleDetail}
          filterData={filterData}
          selectedType={selectedType}
        />
      );
    }

    if (selectedMenu == "add-vehicle") {
      showModal();
    }
  };

  // const addVehicleFormUI = () => {
  //   return (
  //     <Modal
  //       open={open}
  //       title="Add Vehicle"
  //       onOk={handleOk}
  //       onCancel={handleCancel}
  //       footer={false}
  //     >
  //       <Addvehiclesform onAddVehicleHandler={navigateToListOfVehicles} />
  //     </Modal>
  //   );
  // };

  // const MotoCheckModel = () => {
  //   return (
  //     <Modal
  //       open={MotoCheckopen}
  //       title="Moto Check"
  //       onOk={handleOk}
  //       onCancel={handleCancel}
  //       footer={false}
  //     >
  //       <MotoCheck />
  //     </Modal>
  //   );
  // };

  const AddVehicleModel = () => {
    return (
      <Modal
        open={AddVehicleopen}
        title="Add Vehicle"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <AddVehicleWithNZTA onAddVehicleHandler={navigateToListOfVehicles} />
      </Modal>
    );
  };

  return (
    <div>
      {/* {contextHolder} */}
      <div
        className="site-layout-background"
        style={{
          padding: 24,
          minHeight: 360,
        }}
      >
        <SubHeader setSelectedMenu={setSelectedMenu} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 30,
            marginBottom: -20,
          }}
        >
          <div style={{ display: "flex" }}>
            <Search
              placeholder="Search vehicle by plate #"
              allowClear
              enterButton="SEARCH"
              size="medium"
              onSearch={setFilterInput}
            />
            <Space wrap>
              <Select
                defaultValue="All Vehicles Types"
                value={selectedVehicleType}
                onChange={dropDownHandleChange}
                options={[
                  {
                    value: "all",
                    label: "All Vehicles Types",
                  },
                  {
                    value: "Heavy Vehicle",
                    label: "Heavy Vehicle",
                  },
                  {
                    value: "Small Passenger Vehicle",
                    label: "Small Passenger Vehicle",
                  },
                ]}
              />
            </Space>
          </div>
          {profile?.isAdmin && (
            <div>
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                onClick={() => setAddVehicleopen(true)}
              >
                ADD VEHICLE
              </Button>
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                onClick={() => navigate("/app/bulkupload")}
              >
                BULK UPLOAD
              </Button>
            </div>
          )}
        </div>
        <div
          style={{
            paddingTop: 25,
          }}
        >
          {selectActiveComponent()}
        </div>
        {/* {addVehicleFormUI()}
        {MotoCheckModel()} */}
        {AddVehicleModel()}
      </div>
    </div>
  );
}

export default VehiclesScreen;

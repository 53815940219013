import {
  Avatar,
  Card,
  Modal,
  Result,
  Button,
  Row,
  Divider,
  message,
  Col,
} from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteVehilce,
  fetchAllVehiclesAPI,
  unassignVehicleFromDriverAPI,
} from "../store/reducers/vehicleReducer";
import { DeleteOutlined } from "@ant-design/icons";
import Editvehicleform from "../pages/editvehicleform";
const { Meta } = Card;

const Vehicledetails = ({ Id }) => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [loadingSpin, setLoadingSpinner] = useState(false);
  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const [messageApi, contextHolder] = message.useMessage();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const profile = useSelector(
    (state) => state?.persist?.operator?.operatorProfile?.operator
  );

  const EditVehicleFormModel = () => {
    const handleCancel = () => {
      setIsEditModalOpen(false);
    };
    return (
      <>
        <Modal
          title="Edit Vehicle"
          style={{ padding: 0 }}
          open={isEditModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Editvehicleform />
        </Modal>
      </>
    );
  };

  const DeleteModel = () => {
    const handleCancel = () => {
      setIsDeleteModalOpen(false);
    };

    const confirmDelete = () => {
      const payload = {
        accessToken: token,
        activeVehicleID: activevehicle?.id,
      };
      dispatch(deleteVehilce(payload)).then((response) => {
        if (response?.payload?.data?.success === true) {
          dispatch(fetchAllVehiclesAPI(token)).then(() => {
            messageApi.open({
              type: "success",
              content: response?.payload?.data?.message,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
            setIsDeleteModalOpen(false);
            navigate(`/app/vehicles`);
          });
        }
        if (response?.payload?.success === false) {
          if (response?.payload?.message) {
            messageApi.open({
              type: "warning",
              content: response.payload.message,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
          } else if (response.payload.error) {
            messageApi.open({
              type: "warning",
              content: response.payload.error,
              className: "custom-class",
              style: {
                marginTop: "10vh",
              },
            });
          }
          setIsDeleteModalOpen(false);
        }
      });
    };

    return (
      <>
        <Modal
          style={{ padding: 0 }}
          open={isDeleteModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          <Result
            style={{ padding: 0 }}
            status="warning"
            title="Confirm delete?"
            subTitle="Are you sure you want to delete this Vehicle?"
            extra={[
              <Button onClick={confirmDelete} type="danger" key="console">
                DELETE
              </Button>,
              <Button onClick={handleCancel}>CANCEL</Button>,
            ]}
          />
        </Modal>
      </>
    );
  };
  const dispatch = useDispatch();
  let token = useSelector((state) => state?.persist?.auth?.token);
  let activevehicle = useSelector(
    (state) => state?.persist?.vehicle?.activeVehicle
  );
  let state = useSelector((state) => state);

  const onClickdeleteVehicle = () => {
    showDeleteModal();
  };
  const navigateUpdateVehicle = () => {
    navigate(`/app/vehicles/editvehicle`);
    // showEditModal();
    // props.onSelectVehicleHandler()
  };

  useEffect(() => {
    dispatch(fetchAllVehiclesAPI(token));
  }, []);
  let fetchAllVehicles = useSelector(
    (state) => state?.persist?.vehicle?.vehiclesList
  );

  return (
    <div>
      {contextHolder}
      <Card
        title={
          <span
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              textAlign: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            Vehicle Details
          </span>
        }
        extra={
          profile?.isAdmin
            ? [
                <Button
                  onClick={onClickdeleteVehicle}
                  danger
                  icon={<DeleteOutlined />}
                  block
                >
                  DELETE
                </Button>,
              ]
            : []
        }
        size="small"
        bodyStyle={{ textAlign: "left" }}
      >
        {fetchAllVehicles?.map((e) => {
          if (e?.id == Id)
            return (
              <Row
                key={e?.id}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <img
                  style={{
                    width: "25%",
                    margin: "auto",
                    // paddingBottom: "10px",
                  }}
                  alt="VehicleDetails"
                  src={
                    "https://cdn-icons-png.flaticon.com/512/1942/1942728.png"
                  }
                />
                <Col style={{ paddingLeft: 10, marginTop: 50 }}>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-around",
                    }}
                  >
                    <Col
                      style={{
                        lineHeight: 1,
                        fontWeight: 600,
                        paddingRight: 52,
                      }}
                    >
                      <p style={{ fontSize: 10, marginBottom: 0 }}>
                        PlateNumber
                      </p>
                      <p>#{e?.plateNumber}</p>
                    </Col>
                    <Col
                      style={{
                        lineHeight: 1,
                        fontWeight: 600,
                        paddingRight: 52,
                      }}
                    >
                      <p style={{ fontSize: 10, marginBottom: 0 }}>Odometer</p>
                      <p> {e?.initialOdometer}</p>
                    </Col>
                    <Col
                      style={{
                        lineHeight: 1,
                        fontWeight: 600,
                        paddingRight: 52,
                      }}
                    >
                      <p style={{ fontSize: 10, marginBottom: 0 }}>
                        Vehicle Type
                      </p>
                      <p>{e?.type}</p>
                    </Col>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-around",
                      // width: "50%",
                    }}
                  >
                    <Col
                      style={{
                        lineHeight: 1,
                        fontWeight: 600,
                        paddingRight: 52,
                      }}
                    >
                      <p style={{ fontSize: 10, marginBottom: 0 }}>Make</p>
                      <p>{e?.make_nzta}</p>
                    </Col>
                    <Col
                      style={{
                        lineHeight: 1,
                        fontWeight: 600,
                        paddingRight: 52,
                      }}
                    >
                      <p style={{ fontSize: 10, marginBottom: 0 }}>Model</p>
                      <p style={{ lineHeight: 1, fontWeight: 600 }}>
                        {" "}
                        {e?.model_nzta}
                      </p>
                    </Col>
                  </div>
                </Col>
              </Row>
            );
        })}
        <DeleteModel />
        <EditVehicleFormModel />
      </Card>
    </div>
  );
};

export default Vehicledetails;

import { CheckOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getAllSubcriptionPlans,
  preCheckout,
} from "../store/reducers/paymentReducer";

function SubscriptionPlans() {
  const dispatch = useDispatch();

  const subscriptionPlans = useSelector(
    (state) => state?.persist?.payment?.paymentPlans
  );
  const token = useSelector((state) => state?.persist?.auth?.token);

  useEffect(() => {
    dispatch(getAllSubcriptionPlans());
    dispatch(preCheckout(token));
  }, [dispatch, token]);

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      {/* Breadcrumb Navigation */}
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/app/dashboard">Dashboard</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Subscription Plans</Breadcrumb.Item>
      </Breadcrumb>

      {/* Subscription Plans List */}
      <Col style={{ marginTop: "20px" }}>
        <Row style={{ display: "flex", justifyContent: "space-around" }}>
          {subscriptionPlans?.map((plan) => (
            <>
              <Card
                key={plan?.planId}
                title={plan?.name}
                headStyle={{
                  fontSize: "16px",
                  backgroundColor: "#1890FF",
                  color: "white",
                }}
                style={{
                  width: 420,
                  height: 350,
                  marginTop: 40,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                }}
              >
                {/* Plan Cost and Billing Cycle */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      marginRight: "5px",
                    }}
                  >
                    {plan?.cost}
                  </p>
                  <p>{plan?.currency}</p>
                  <p>{` / ${plan?.billingCycle}`}</p>
                </div>

                {/* Plan Benefits */}
                {plan?.planBenefits?.map((benefit) => (
                  <Col
                    key={benefit?.benefitName}
                    style={{ justifyContent: "flex-start" }}
                  >
                    <p>
                      <CheckOutlined
                        style={{
                          color: "#1890FF",
                          fontSize: "13px",
                          paddingRight: 5,
                        }}
                      />
                      {benefit?.benefitName}
                    </p>
                  </Col>
                ))}
                <Button
                  size="middle"
                  type="primary"
                  style={{
                    borderColor: "#1890FF",
                    position: "absolute",
                    bottom: 20,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <a href="mailto:help@elogbook.co.nz">Contact Us</a>
                </Button>
              </Card>
            </>
          ))}
        </Row>
      </Col>
    </div>
  );
}

export default SubscriptionPlans;

import React, { useRef, useState, useEffect } from "react";
import "antd/dist/antd.css";
import moment from "moment";
import {
  Button,
  Input,
  Space,
  Table,
  Modal,
  Form,
  Select,
  Card,
  Spin,
  message,
  DatePicker,
  Row,
  Col,
  Popconfirm,
  Radio,
  Typography,
} from "antd";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDriverData,
  updateDriverProfile,
  fetchAllDrivers,
  addDriver,
} from "../store/reducers/driverReducer";
import PhoneInput from "react-phone-number-input";
// import { deleteDriverData, updateDriverProfile, fetchDriver} from '../store/reducers/driverReducer'
import { useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { selectDriver } from "../store/reducers/vehicleReducer";
import FormItemLabel from "antd/es/form/FormItemLabel";
import {
  MailOutlined,
  PhoneOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  IdcardOutlined,
  CarOutlined,
  BankOutlined,
  StopOutlined,
  NumberOutlined,
  SnippetsOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

const ActiveDriversList = ({ parentToChild }) => {
  const { Option } = Select;
  const { Column, ColumnGroup } = Table;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const access_token = useSelector((state) => state?.persist?.auth.token);
  const dispatch = useDispatch();
  const [isDeleteDriverModalOpen, setDeleteDriverModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [AddDriverform] = Form.useForm();
  const [form1] = Form.useForm();
  const [driver_id, setDriverId] = useState(null);
  const [driver, setUpdateDriver] = useState();
  const [isUpdateDriverModalOpen, setUpdateDriverModalOpen] = useState(false);
  const [show_active_drivers, setShowDrivers] = useState(false); // show_inactive_drivers
  const [show_inactive_drivers, setShowInactiveDrivers] = useState(false);
  const [showDriverSummary, setShowDriverSummary] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isAddDriverModalOpen, setAddDriverModal] = useState();
  let [startDate, setStartDate] = useState();
  const [value, setValue] = useState();
  const navigate = useNavigate();
  const [filterInput, setFilterInput] = useState("");
  const { Search } = Input;
  const [buttonText, setbuttonText] = useState("DELETE DRIVER");
  const profile = useSelector(
    (state) => state?.persist?.operator?.operatorProfile?.operator
  );

  const newDate = new Date();
  const year = newDate.getFullYear() - 18;
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const eligibleDate = `${year}-${month > 10 ? month : "0" + month}-${
    day > 10 ? day : "0" + day
  }`;

  const headers = [
    { label: "Driver Id", key: "id" },
    { label: "Driver Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Licence Number", key: "licenseNumber" },
    { label: "Date Of Birth", key: "dateOfBirth" },
    {
      label: "Inactive",
      key: "inactive",
    },
    {
      label: "Is Deleted",
      key: "isDeleted",
    },
    {
      label: "Deleted Reason",
      key: "deletedReason",
    },
    {
      label: "Vehicle Owned by Driver",
      key: "isDriverVehicle",
    },
    {
      label: "Violations count",
      key: "violationCount",
    },
    {
      label: "Organization",
      key: "operatorOrganizationName",
    },
  ];

  let activeDriver = [];
  let activeDriverCount = [];
  let inactiveDrivers = [];
  let driverData = [];
  driverData = useSelector((state) => state?.persist?.driver?.drivers);
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const onReset = () => {
    form.resetFields();
  };
  const cardStyle = {
    width: 140,
    height: 90,
  };

  let cardData = [
    {
      text: "Drivers",
      value: driverData,
    },
    {
      text: "Active Drivers",
      value: activeDriverCount,
    },
    {
      text: "Inactive Drivers",
      value: inactiveDrivers,
    },
  ];
  const onFinish = (values) => {
    setLoadingSpin(true);
    let payload = {
      firstname: values.firstname,
      lastname: values.lastname,
      phoneNumber: values.phoneNumber,
      email: values.email,
      driverTypeId: values.driverTypeId,
      dateOfBirth: startDate,
      licenseNumber: values.licenseNumber,
      access_token: access_token,
    };
    dispatch(addDriver(payload)).then((res) => {
      setLoadingSpin(false);
      if (res.payload.data.success) {
        AddDriverform.resetFields();
        setAddDriverModal(false);
        messageApi.open({
          type: "success",
          content: res.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        dispatch(dispatch(fetchAllDrivers(access_token)));
      }
      if (res.payload.data.success === false) {
        if (res.payload.data.error) {
          messageApi.open({
            type: "warning",
            content: res.payload.data.error,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
        }
        if (res.payload.data.message) {
          messageApi.open({
            type: "warning",
            content: res.payload.data.message,
            className: "custom-class",
            style: {
              marginTop: "10vh",
            },
          });
        }
      }
    });
  };

  const filterData = () => {
    if (filterInput === "") return activeDriver;

    if (isNaN(filterInput)) {
      return activeDriver.filter(({ name }) =>
        name.toLowerCase().includes(filterInput.toLowerCase())
      );
    }
  };
  const todaysdate = new Date().toLocaleString();

  const onChange = (date, dateString) => {
    let date1 = new Date(dateString);
    let year = date1.getFullYear();
    let month = date1.getMonth() + 1;
    let dt = date1.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    startDate = year + "-" + month + "-" + dt;
    setStartDate(startDate);
    // alert(year+'-' + month + '-'+dt);
  };

  const onEditChangeDate = (date) => {
    let date1 = new Date(date);

    let year = date1.getFullYear();
    let month = date1.getMonth() + 1;
    let dt = date1.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    startDate = year + "-" + month + "-" + dt;
    return startDate;
    // alert(year+'-' + month + '-'+dt);
  };

  // useEffect(() => {
  //   if (access_token !== "") {
  //     setLoadingSpin(true);

  //     dispatch(fetchAllDrivers(access_token)).then((res) => {
  //       setLoadingSpin(false);

  //       if (res.payload.data.error) {
  //         messageApi.open({
  //           type: "warning",
  //           content: res.payload.data.error,
  //           className: "custom-class",
  //           style: {
  //             marginTop: "10vh",
  //           },
  //         });
  //       }
  //     });
  //   }
  // }, [dispatch]);

  const styles = {
    text_input: {
      textAlign: "left",
    },
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  // LOGIC TO CHANGE THE DRIVER STATUS TO IN ACTIVE
  // if (driverData !== undefined && driverData !== null) {
  //   driverData.map((driverItem) => {

  //     const driverLogs = { ...driverItem }
  //     let toodaysDate2 = new Date();
  //     let latestLogDate = new Date("2022-12-11T10:15:53.328Z");
  //     var time_difference = toodaysDate2.getTime() - latestLogDate.getTime();
  //     var noOfDays = time_difference / (1000 * 60 * 60 * 24);

  //     if (noOfDays >= 30) {
  //       driverLogs.inactive = true
  //       activeDriver.push(driverLogs);
  //       //return driverLogs
  //     } else  {
  //       activeDriver.push(driverLogs);
  //     }

  //   });
  // }

  if (driverData !== undefined && driverData !== null) {
    driverData.map((driverItem) => {
      if (driverItem.inactive === false || null) {
        activeDriver.push(driverItem);
      }
      if (driverItem.inactive === false || null) {
        activeDriverCount.push(driverItem);
      }
      // currently we have to shoow all active and inactive driver list
    });
  }

  if (driverData !== undefined && driverData !== null) {
    driverData &&
      driverData.map((driverItem) => {
        if (driverItem.inactive === true || null) {
          inactiveDrivers.push(driverItem);
        }
      });
  }

  const openAddDriverModal = () => {
    setAddDriverModal(true);
  };

  const closeDriverModal = () => {
    setAddDriverModal(false);
  };

  const handleCancel = () => {
    setDeleteDriverModalOpen(false);
    setShowDriverSummary(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  // const handleCancel = () => {
  //   setDeleteDriverModalOpen(false);
  //   setShowDriverSummary(false);
  // };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Row
            style={{ justifyContent: "space-around" }}
            flexDirection="horizontal"
          >
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              SEARCH
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              RESET
            </Button>
          </Row>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            RELOAD
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const driverType = [
    { id: 1, name: "Heavy Vehicle Driver" },
    { id: 2, name: "Small Passenger Vehicle Driver" },
  ];
  const [violationsDataforTabel, setViolationsDataforTabel] = useState("");
  const [isViolationsModelOpen, setIsViolationsModelOpen] = useState(false);
  const [isModalRowClickDisabled, setIsModalRowClickDisabled] = useState(false);
  const violationsHandleCancel = () => {
    setIsViolationsModelOpen(false);
    setIsModalRowClickDisabled(false);
  };
  const violationsColumns = [
    {
      title: "Date & Time",
      width: 100,
      key: "date",
      render: (record) => (
        <>
          <Space size="small">
            {moment(record?.createdAt).format("DD-MM-YYYY hh:mm A")}
          </Space>
        </>
      ),
    },
    {
      title: "Driver",
      key: "driver",
      render: (record) => (
        <>
          <Space size="small">{record?.driver}</Space>
        </>
      ),
    },
    {
      title: "Message",
      width: 400,
      key: "message",
      render: (record) => (
        <>
          <Space size="small">
            {record?.businessRuleViolated?.rule?.errorMessage}
          </Space>
        </>
      ),
    },
    {
      title: "Warning",
      key: "warning",
      render: (record) => (
        <>
          <Space size="small">
            {" "}
            {record?.businessRuleViolated?.rule?.rule_type}
          </Space>
        </>
      ),
    },
  ];
  const baseColumns = [
    {
      title: "Driver Details",
      width: "16.5%",
      key: "name",
      render: (record) => (
        <>
          <Space size="middle">
            <UserOutlined />
            {record?.name}
          </Space>
          <br />
          <Space size="middle">
            <IdcardOutlined />
            {record?.licenseNumber}
          </Space>
        </>
      ),
    },
    {
      title: "Contact Driver",
      key: "contact",
      width: "25%",
      render: (record) => (
        <>
          <Space size="middle">
            <MailOutlined />
            {record?.email}
          </Space>
          <br />
          <Space size="middle">
            <PhoneOutlined />
            {record?.phoneNumber}
          </Space>
        </>
      ),
    },
    {
      title: "Vehicle Details",
      width: "14.6%",
      key: "Vehicledetails",
      render: (record) => (
        <>
          {record?.vehicleNumber ? (
            <Space size="middle">
              <NumberOutlined />
              {record?.vehicleNumber}
            </Space>
          ) : (
            <Space size="middle">
              <StopOutlined /> Unassigned
            </Space>
          )}
          <br />
          {record.isDriverVehicle === null ? (
            <Space size="middle">
              <BankOutlined /> Organization
            </Space>
          ) : (
            <Space size="middle">
              <UserOutlined /> Driver
            </Space>
          )}
        </>
      ),
    },
    {
      title: "Weekly Cumulative Work (hh:mm:ss)",
      width: "15.6%",
      key: "workhours",
      render: (record) => (
        <>
          <LineChartOutlined />
          <Space size="middle">
            {record?.weekly_work_hours[0]?.data?.CumulativeDriveTime}
          </Space>
        </>
      ),
    },
    {
      title: "Violations",
      width: "8.9%",
      key: "violationCount",
      render: (record) => (
        <>
          <a>{record?.violationCount + " "}</a>
          <a
            onClick={() => {
              setIsViolationsModelOpen(true);
              setViolationsDataforTabel(record?.violationDetails);
            }}
          >
            View
          </a>
          <Modal
            title="Violation Details"
            style={{ padding: 0 }}
            width={900}
            open={isViolationsModelOpen}
            onCancel={violationsHandleCancel}
            footer={null}
          >
            <Table
              dataSource={violationsDataforTabel}
              scroll={{ y: 350 }}
              columns={violationsColumns}
              pagination={false}
              size="middle"
            />
          </Modal>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "inactive",
      width: "10.5%",
      key: "Status",
      render: (inactive, isDeleted) => {
        const getStatusStyle = (bgColor) => ({
          fontSize: 14,
          fontWeight: "600",
          letterSpacing: "2px",
          marginBottom: "0px",
          border: "double",
          color: "white",
          display: "flex",
          justifyContent: "center",
          borderRadius: "5px",
          backgroundColor: bgColor,
        });

        if (inactive && isDeleted.isDeleted) {
          return <p style={getStatusStyle("red")}>Deleted</p>;
        } else if (inactive && !isDeleted.isDeleted) {
          return <p style={getStatusStyle("orange")}>Inactive</p>;
        } else if (!inactive && !isDeleted.isDeleted) {
          return <p style={getStatusStyle("#05ad48")}>Active</p>;
        }
      },
    },
    {
      title: "View Logs",
      dataIndex: "",
      width: "9%",
      key: "viewLogs",
      render: (record) => (
        <Space size="middle">
          <a onClick={() => ViewDriverLog(record)}>
            <SnippetsOutlined /> View
          </a>
        </Space>
      ),
    },
  ];

  const adminColumns = [
    {
      title: "Action",
      dataIndex: "",
      width: "8.3%",
      key: "action",
      render: (record) => (
        <>
          <Space size="middle">
            {record.isDeleted ? (
              <a disabled>
                <EditOutlined /> Edit
              </a>
            ) : (
              <a
                onClick={() => {
                  if (profile?.isAdmin) editDriver(record);
                }}
              >
                <EditOutlined /> Edit
              </a>
            )}
          </Space>
          <br />
          {!record.isDeleted && (
            <Space size="middle">
              <a
                onClick={() => {
                  if (profile?.isAdmin) deleteTheDriver(record.id);
                }}
              >
                <DeleteOutlined /> Delete
              </a>
            </Space>
          )}
        </>
      ),
    },
  ];

  const columns = profile?.isAdmin
    ? [
        ...baseColumns.slice(0, baseColumns.length - 1), // All columns except "View Logs"
        ...adminColumns, // Add admin columns here
        ...baseColumns.slice(baseColumns.length - 1), // Add the "View Logs" column back
      ]
    : baseColumns;

  // setUpdateDriverModalOpen
  const [StatusisDelete, setStatusisDelete] = useState("");
  const [StatusisInactive, setStatusisInactive] = useState("");
  const [reasonToDelete, setReasonToDelete] = useState("");

  const closeDriverProfile = () => {
    // setDeleteDriverModalOpen(false);
    // setShowDriverSummary(false)
    setUpdateDriverModalOpen(false);
  };

  useEffect(() => {
    onSubmit();
  }, [StatusisDelete, StatusisInactive, reasonToDelete]);

  const deleteDriver = (values) => {
    setLoadingSpin(true);
    setReasonToDelete(values.reason);
    if (values.status === 1) {
      setStatusisDelete(false);
      setStatusisInactive(true);
    } else if (values.status === 2) {
      setStatusisDelete(true);
      setStatusisInactive(false);
    }
  };

  const onSubmit = () => {
    if (StatusisDelete !== "" && StatusisInactive !== "") {
      callingDeleteDriverAPI();
    }
  };

  const callingDeleteDriverAPI = () => {
    const payload = {
      reason: reasonToDelete,
      isDelete: StatusisDelete,
      isInactive: StatusisInactive,
      access_token: access_token,
      driver_id: driver_id,
    };
    dispatch(deleteDriverData(payload)).then((result) => {
      if (result?.payload?.data?.success == true) {
        form1.resetFields();
        messageApi.open({
          type: "success",
          content: result.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        setDeleteDriverModalOpen(false);
        setLoadingSpin(false);
        dispatch(fetchAllDrivers(access_token));
      }
      if (result?.payload?.data?.success == false) {
        messageApi.open({
          type: "warning",
          content: result.payload.data.error,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
        setLoadingSpin(false);
      }
    });
  };

  // const activeDriversList = () => {
  //   setShowDrivers(true);
  //   setShowDriverSummary(false);
  // };

  // const inactiveDriverList = () => {
  //   setShowInactiveDrivers(true);
  //   setShowDrivers(false);
  //   setShowDriverSummary(false);
  // };

  //setShowInactiveDrivers

  const deleteTheDriver = (id) => {
    setDriverId(id);
    setDeleteDriverModalOpen(true);
  };

  const onUpdateDriver = (driverDataToUpdate) => {
    setLoadingSpin(true);
    // let driverId = driver.id;
    let payload = {
      firstname: driverDataToUpdate.firstname,
      lastname: driverDataToUpdate.lastname,
      phoneNumber: driverDataToUpdate.phoneNumber,
      dateOfBirth: onEditChangeDate(driverDataToUpdate.dateOfBirth),
      driverTypeId: driverDataToUpdate.driverTypeId,
      email: driverDataToUpdate.email,
      licenseNumber: driverDataToUpdate.licenseNumber,
      access_token: access_token,
      driverId: driver.id,
    };
    dispatch(updateDriverProfile(payload)).then((result) => {
      if (result.payload.data.success) {
        form.resetFields();
        messageApi.open({
          type: "success",
          content: result.payload.data.message,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
      } else if (result.payload.data.error !== undefined) {
        form.resetFields();
        messageApi.open({
          type: "warning",
          content: result.payload.data.error,
          className: "custom-class",
          style: {
            marginTop: "10vh",
          },
        });
      }
      dispatch(fetchAllDrivers(access_token)).then((result) => {
        // alert(JSON.stringify(result.payload.data.success))
        setLoadingSpin(false);
        // if(result.payload.data.success) {
        //   setLoadingSpin(false)
        // }
      });
    });
  };

  // const addDriver = () => {
  //   setAddDriverModal(true)
  // }

  const updateDriver = () => {
    setUpdateDriverModalOpen(false);
    form
      .validateFields()
      .then((values) => {
        // alert(JSON.stringify(values))
        onUpdateDriver(values);
      })
      .catch((info) => {});
  };
  const ViewDriverLog = (evt) => {
    dispatch(selectDriver(evt));
    navigate(`/app/drivers/driversloghistory/${evt?.id}`);
  };

  const cardComponenet = () => {
    return cardData?.map(
      (cardValue) =>
        cardValue && (
          <div>
            <Card
              title={cardValue.text}
              type="inner"
              size="small"
              style={cardStyle}
            >
              {cardValue.value !== undefined ? (
                <>
                  <div>{cardValue?.value?.length}</div>
                </>
              ) : (
                0
              )}
            </Card>
          </div>
        )
    );
  };

  function editDriver(driver) {
    setUpdateDriver(driver);
    setUpdateDriverModalOpen(true);

    form.setFieldsValue({
      firstname: driver.firstname,
      lastname: driver.lastname,
      email: driver.email,
      phoneNumber: driver.phoneNumber,
      dateOfBirth: moment(new Date(driver.dateOfBirth), "DD/MM/YYYY"),
      // dateOfBirth:new Date(driver.dateOfBirth),
      // dateOfBirth:moment(new Date().getDate(),'DD/MM/YYYY'),
      driverTypeId: driver.driverType.id,
      licenseNumber: driver.licenseNumber,
    });
  }

  return (
    <div>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 12,
        }}
        style={{
          marginLeft: 2,
          justifyContent: "space-between",
          // width: "40%",
        }}
      >
        {cardComponenet()}
        {/* <Row style={{ justifyContent: "flex-end", marginTop: "10px" }}> */}
        <Col style={{ alignSelf: "flex-end", marginBottom: "-20px" }}>
          <Search
            placeholder="Search driver by name"
            allowClear
            enterButton="Search"
            size="medium"
            onSearch={setFilterInput}
          />
        </Col>
        {profile?.isAdmin && (
          <Col style={{ alignSelf: "flex-end", marginBottom: "-20px" }}>
            {/* <Button>
              {" "}
              <CSVLink
                data={activeDriver}
                headers={headers}
                filename={`Drivers_Details(${todaysdate}).csv`}
                target="_blank"
              >
                EXPORT TABLE
              </CSVLink>
            </Button> */}
            {console.log(activeDriver, "activeDriver")}
            <Button
              type="primary"
              style={{ marginLeft: "50px" }}
              onClick={openAddDriverModal}
            >
              ADD DRIVER
            </Button>
          </Col>
        )}
        {/* </Row> */}
      </Row>
      <Row style={{ marginTop: "30px" }} span={12}>
        {contextHolder}
        <Spin spinning={loadingSpin} size="large">
          <Table
            dataSource={filterData()}
            scroll={{
              y: "40vh",
            }}
            // onRow={
            //   isModalRowClickDisabled
            //     ? null
            //     : (record) => ({
            //         style: { cursor: "pointer" },
            //         onClick: (event) => {
            //           console.log("onRow onClick", record);
            //           // setViolationsDataforTabel(record?.violationDetails);
            //           // setIsViolationsModelOpen(true);
            //           setIsModalRowClickDisabled(true);
            //         },
            //       })
            // }
            columns={columns}
            pagination={false}
            size={"small"}
          ></Table>
          <Modal
            open={isDeleteDriverModalOpen}
            // onOk={deleteDriver}
            onCancel={handleCancel}
            footer={false}
          >
            <Typography.Title level={4}>Confirm</Typography.Title>
            <Form
              form={form1}
              layout="vertical"
              onFinish={deleteDriver}
              name="form_in_modal"
              initialValues={{
                modifier: "public",
              }}
            >
              <FormItemLabel
                style={{ fontSize: 8, marginBottom: 10 }}
                label={
                  "Choose inactive if you want to temporarily disable the account"
                }
              ></FormItemLabel>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: "required!",
                  },
                ]}
                onChange={(value) => {
                  if (value?.target.value === "2") {
                    setbuttonText("Delete Driver");
                  } else if (value?.target.value === "1") {
                    setbuttonText("Inactivate Driver");
                  }
                }}
              >
                <Radio.Group>
                  <Radio value={1}>Inactive</Radio>
                  <Radio value={2}>Delete</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Enter the reason"
                name="reason"
                rules={[
                  {
                    required: true,
                    message: "required!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  ...layout.wrapperCol,
                  lg: {
                    offset: 8,
                  },
                  md: {
                    offset: 8,
                  },
                  sm: {
                    offset: 8,
                  },
                }}
              >
                <Row
                  style={{
                    justifyContent: "flex-end",
                    width: 295,
                  }}
                >
                  <Col>
                    <Button
                      style={{ marginTop: 10 }}
                      onClick={handleCancel}
                      block
                    >
                      CANCEL
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: 10, marginLeft: 20 }}
                      block
                    >
                      {buttonText}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            open={isUpdateDriverModalOpen}
            onCancel={closeDriverProfile}
            title={"Update Driver"}
            // cancelButtonProps={{ style: { display: "none" } }}
            // okButtonProps={{ style: { display: "none" } }}
            footer={false}
          >
            <Form
              form={form}
              {...layout}
              validateMessages={validateMessages}
              onFinish={updateDriver}
              // layout="vertical"
              name="form_in_modal"
              initialValues={{
                modifier: "public",
              }}
            >
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                    message: "Enter valid First name",
                  },
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z. ]{0,15}$/),
                    message: "Enter valid Last Name",
                  },
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    pattern: new RegExp(/^.{5,13}$/),
                    message: "Enter valid Phone Number",
                  },
                  {
                    required: true,
                  },
                ]}
              >
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry="NZ"
                  style={styles.phoneinput}
                  value={value}
                  onChange={setValue}
                  className="ant-input"
                />
              </Form.Item>
              {/* <Form.Item
                label="Date of Birth"
                name="dateOfBirth"
                rules={[
                  {
                    required: true,
                    message: "required!",
                  },
                ]}
              >
                <Input /> */}
              <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                style={styles.text_input}
                rules={[
                  {
                    required: true,
                  },
                  {
                    required: false,
                    message: "Enter a valid date of birth",
                  },
                ]}
              >
                {/* <DatePicker selected={startDate} onChange={(date) => {setDate(date)}} /> */}
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(d) => !d || d.isAfter(eligibleDate)}
                />
              </Form.Item>
              {/* </Form.Item> */}
              <Form.Item
                label="Driver Type"
                name="driverTypeId"
                rules={[
                  {
                    required: true,
                    message: "Driver Type required!",
                  },
                ]}
              >
                {/* <Select placeholder="Driver Type" allowClear>
                  <Option value="1">Heavy Vehicle Driver</Option>
                  <Option value="2">Small Passenger Vehicle Driver</Option>
                </Select> */}
                <Select placeholder={driverType.name} allowClear>
                  {driverType.map((e) => {
                    return <Option value={e?.id}>{e?.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Enter valid email",
                  },
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="License Number"
                name="licenseNumber"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9-]{0,15}$/),
                    message: "Enter the valid license number",
                    required: true,
                  },
                ]}
              >
                <Input
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                  placeholder="License Number"
                />
              </Form.Item>

              <Form.Item
                // justify="space-around"
                wrapperCol={{
                  ...layout.wrapperCol,
                  lg: {
                    offset: 8,
                  },
                  md: {
                    offset: 8,
                  },
                  sm: {
                    offset: 8,
                  },
                }}
              >
                <Row style={{ justifyContent: "space-around" }}>
                  <Col span={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: 10 }}
                      block
                    >
                      UPDATE
                    </Button>
                  </Col>
                  <Col span={11}>
                    <Button
                      htmlType="submit"
                      style={{ marginTop: 10 }}
                      onClick={onReset}
                      block
                    >
                      RESET
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            open={isAddDriverModalOpen}
            title="Add Driver"
            onCancel={closeDriverModal}
            footer={false}
          >
            <Spin spinning={loadingSpin} size="large">
              <Form
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
                form={AddDriverform}
                // style={styles.text_input}
              >
                <Form.Item
                  name="firstname"
                  label="First Name"
                  style={styles.text_input}
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z. ]{3,15}$/),
                      message: "Enter valid First Name",
                    },
                  ]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  name="lastname"
                  label="LastName"
                  style={styles.text_input}
                  rules={[
                    {
                      required: true,
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z. ]{3,15}$/),
                      message: "Enter valid Last Name",
                    },
                  ]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  label="Phone No."
                  style={styles.text_input}
                  rules={[
                    {
                      pattern: new RegExp(/^.{5,13}$/),
                      message: "Enter valid Phone Number",
                      required: true,
                    },
                  ]}
                >
                  <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="NZ"
                    style={styles.phoneinput}
                    value={value}
                    onChange={setValue}
                    className="ant-input"
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  style={styles.text_input}
                  rules={[
                    {
                      required: true,
                    },
                    {
                      type: "email",
                      message: "Enter the valid email",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="driverTypeId"
                  label="Type"
                  style={styles.text_input}
                  rules={[
                    {
                      required: true,
                      message: "Driver Type required!",
                    },
                  ]}
                >
                  <Select placeholder="Select Type" allowClear>
                    {driverType.map((e) => {
                      return <Option value={e?.id}>{e?.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="dateOfBirth"
                  label="Date of Birth"
                  style={styles.text_input}
                  rules={[
                    {
                      required: true,
                    },
                    {
                      required: false,
                      message: "Enter a valid date of birth",
                    },
                  ]}
                >
                  {/* <DatePicker selected={startDate} onChange={(date) => {setDate(date)}} /> */}
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(d) => !d || d.isAfter(eligibleDate)}
                    onChange={onChange}
                  />
                </Form.Item>
                <Form.Item
                  name="licenseNumber"
                  label="License No."
                  style={styles.text_input}
                  rules={[
                    {
                      pattern: new RegExp(/^[a-zA-Z0-9-]{0,15}$/),
                      message: "Enter the valid license number",
                      required: true,
                    },
                  ]}
                >
                  <Input
                    onInput={(e) =>
                      (e.target.value = e.target.value.toUpperCase())
                    }
                    placeholder="License Number"
                  />
                </Form.Item>

                <Form.Item
                  // justify="space-around"
                  wrapperCol={{
                    ...layout.wrapperCol,
                    lg: {
                      offset: 8,
                    },
                    md: {
                      offset: 8,
                    },
                    sm: {
                      offset: 8,
                    },
                  }}
                >
                  <Row style={{ justifyContent: "space-between" }}>
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginTop: 10 }}
                        block
                      >
                        ADD DRIVER
                      </Button>
                    </Col>
                    <Col span={10}>
                      <Button
                        htmlType="submit"
                        style={{ marginTop: 10 }}
                        onClick={onReset}
                        block
                      >
                        RESET
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Spin>
          </Modal>
        </Spin>
      </Row>
    </div>
  );
};
export default ActiveDriversList;

// ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQDAkPyduod7AdY6zDAaVnuyUffJ3IOnfkTIraBm92ncKg6zx1fG8z2K3GwQCBsMcmQEqJI5FNLxPvec7HalAL3CSJ81luL7zQFCXMT0C+NDwCgKQihYvUDXpLbLaE3+m4AsBZBJEIigzpxBXQt1XHL9odzL4MQZ4PFQ02hRVZVeM2cqcx29llVS/M5F7IgYmeYUv6WLBvGzfZlZdA+Q+rT1JvefoXiT6ao9AdvtOqUEjHE54VqISpFMDaTYdB/F1KRYq34b0LKAwIZqb/SQKCJ+dp5Oy1Xy9LGRqRVGJQKfpAojVO5WfjUJGEzZZLVYfSe2Nv4edJnxwWDgJnCYX3rDU8RjfP3cCloouL4sYIgN/f4yc65PR/ooL7bXerhz7vyoeJ5ut0ICz2GI8XGuN748mSFsZ2S8F/tgiguCU+PHimNcSb5+FBus+kLs/wQsd3pm6kgx9skuJhm7i4x6PX+NQkGGQUjQm0jlg5TXkwApoEfLSub/Kf17q1LTnRXhLjhBn1NjwK6XNKO3qybmmsVDIwj+va9S794FdhMWCALW6wOe0mh5n6TilsOCni/DCditDC6Gn/Xa/KX0LlsoVGqeGwGUXk0RREo1t2ZuVRF1Q/5CByLrWOtJr+EhmBrIJFmZk7v+TfqFTTE/OWis40P84/VXe6vg3Yoehq5BpK8ivw== megha.shelare@vectotech.in

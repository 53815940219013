import API from "../store/utils/APIUtils";
const authToken = "";
// const access_token = useSelector((state) => state?.persist?.auth.token)

export const operatorLoginAPIHelper = async (token) => {
  try {
    const response = await API.post(
      `/api/v1/operator/login/`,
      {
        token_id: token,
      },
      {
        headers: {
          //Authorization: "token " + authToken, bussiness_name //
          "Content-Type": "application/json",
        },
      }
    );
    // sessionStorage.setItem("a_token", response.data.token)
    return response;
  } catch (error) {
    if (error?.request) {
      return error?.request?.response;
    } else {
      return error?.response?.response;
    }
  }
};

export const operatorOnboardingAPIHelper = async (operator_data) => {
  const response = await API.post(
    `/api/v1/operator/onboarding/`,
    {
      firstname: operator_data?.firstname,
      lastname: operator_data?.lastname,
      phoneNumber: operator_data?.phoneNumber,
      operatorName: operator_data?.operatorName,
      operatorUrl: operator_data?.email,
      token_id: operator_data?.token_id,
      authProvider: operator_data?.authProvider,
      industry_type_id: operator_data?.industry_type_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // console.log(JSON.stringify(response))
  // {
  //   firstname:"Admin",
  //   lastname:"Operator",
  //   phoneNumber:9090909090,
  //   operatorName:"Tata Motors",
  //   operatorUrl:"https://tatamotors.com",
  //   token_id:token.token_id
  // },
  return response;
};

// export const fetchOperatorProfileAPIHelper = http://localhost:8000/api/v1/4/operator/profile/

export const fetchOperatorProfileAPIHelper = async (
  accessToken,
  operator_id
) => {
  const response = await API.get(`/api/v1/${operator_id}/operator/profile`, {
    headers: {
      Authorization: "token " + accessToken,
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const updateOperatorProfileAPIHelper = async (
  accessToken,
  operator_id,
  firstname,
  lastname,
  phoneNumber
) => {
  const response = await API.put(
    `/api/v1/${operator_id}/operator/profile`,
    {
      firstname: firstname,
      lastname: lastname,
      phoneNumber: phoneNumber,
    },
    {
      headers: {
        Authorization: "token " + accessToken,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const renewAccessTokenAPIHelper = async (refreshToken) => {
  const response = await API.post(
    `/api/v1/operator/refresh/token/`,
    {
      refreshToken: refreshToken,
    },
    {
      headers: {
        // Authorization: "token " + accessToken,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const notificationsAPIHelper = async (payload) => {
  const response = await API.put(
    `/api/v1/operator/collect-firebase-token/`,
    {
      firebasetoken: payload.MfcToken,
    },
    {
      headers: {
        Authorization: "token " + payload.access_token,
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};
export const getOperatorIndustryType = async () => {
  const response = await API.get(`/api/v1/operator/industry/types/`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response?.data;
};

import API from "../store/utils/APIUtils";
export const updateOperatorAPIsHelper = async (payload) => {
  const response = await API.put(
    `/api/v1/operator/profile/`,
    payload.operator_updated_profile,
    {
      headers: {
        Authorization: "Token " + payload.token,
        "Content-Type": "application/json",
      },
    },
  );

  return response;
};

export const opratorProfileAPIsHelper = async (access_token) => {
  const response = await API.get(`/api/v1/operator/profile/`, {
    headers: {
      Authorization: "Token " + access_token,
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const fetchOperatorMembersAPIsHelper = async (access_token) => {
  const response = await API.get(`/api/v1/operator/members/`, {
    headers: {
      Authorization: "token " + access_token,
      "Content-Type": "application/json",
    },
  });

  return response;
};

export const addOperatorMembersAPIsHelper = async (payload) => {
  const response = await API.post(
    `/api/v1/operator/add/member/`,
    {
      firstname: payload.firstname,
      lastname: payload.lastname,
      phoneNumber: payload.phoneNumber,
      isAdmin: payload.isAdmin,
      email: payload.email,
    },
    {
      headers: {
        Authorization: "token " + payload.access_token,
        "Content-Type": "application/json",
      },
    },
  );

  return response;
};
// deleteOperatorMembersAPIsHelper

export const editOperatorMembersAPIsHelper = async (payload) => {
  const response = await API.put(
    `/api/v1/operator/update/member/${payload.operatorMemberId}/`,
    {
      firstname: payload?.firstname,
      lastname: payload?.lastname,
      phoneNumber: payload?.phoneNumber,
      email: payload?.email,
    },
    {
      headers: {
        Authorization: "token " + payload.access_token,
        "Content-Type": "application/json",
      },
    },
  );

  return response;
};

export const deleteOperatorMembersAPIsHelper = async (payload) => {
  const response = await API.delete(
    `/api/v1/operator/delete/member/${payload?.operatorMemberIdForDelete}/`,
    {
      headers: {
        Authorization: "token " + payload?.access_token,
        "Content-Type": "application/json",
      },
      data: { reason: payload?.reason },
    }
  );
  console.log("respdeleteonse", response);

  return response;
};

export const fetchRemoveOperatorAPIsHelper = async (access_token) => {
  const response = await API.get(`/api/v1/operator/deleted/members/`, {
    headers: {
      Authorization: "token " + access_token,
      "Content-Type": "application/json",
    },
  });
  console.log("response", response);

  return response;
};

export const fetchActiveDriversLocationsAPIsHelper = async (access_token) => {
  try {
    const response = await API.get(`/api/v1/operator/dashboard/`, {
      headers: {
        Authorization: "token " + access_token,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (error) {
    return error?.response;
  }
};

export const fetchAddedTeamUserDetailsAPIsHelper = async (uniqueId) => {
  const response = await API.get(
    `/api/v1/operator/accept-invitation/${uniqueId}/`,
    {},
  );

  return response;
};


import axios from "axios";

const dev = false;
export const appVersion = "1.0.0" + (dev ? " (Dev)" : " (Prod)");

const api_endpoint = dev
  ? "https://elogbackend-staging-production.up.railway.app/" // for pipeline/staging
  : `https://elogbackends-production.up.railway.app/`; // for pipeline/production

const API = axios.create({
  baseURL: api_endpoint,
});

export default API;
